<template>
	<div>
		<SettingsChangeAccountNameForm :input-name="currentUser.name" />

		<SettingsChangeAccountContactForm :loading-parent="loading" />

		<SettingsChangeLocaleForm :current-locale="currentUser.locale" />

		<SettingsChangeEmailForm :current-email="currentUser.email" />

		<SettingsChangePasswordForm />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - ${this.$t('settings.account')}`
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		SettingsChangeAccountNameForm: () => import('@/components/settings/SettingsChangeAccountNameForm.vue'),
		SettingsChangeAccountContactForm: () => import('@/components/settings/SettingsChangeAccountContactForm.vue'),
		SettingsChangeLocaleForm: () => import('@/components/settings/SettingsChangeLocaleForm.vue'),
		SettingsChangePasswordForm: () => import('@/components/settings/SettingsChangePasswordForm.vue'),
		SettingsChangeEmailForm: () => import('@/components/settings/SettingsChangeEmailForm.vue')
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser'
		})
	},
	created() {
		this.fetchCurrentUser().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('user', ['fetchCurrentUser'])
	}
}
</script>
